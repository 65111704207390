 .faq
 {
    padding-bottom: 100px;
 }
 
 .faq .nav-link {
    color: #000 !important;
}
.faq .nav-item
{
    width: 33% !important;
    margin-right: 4px;
}
.faq .nav-item button
{
    width: 100% !important;
    background: #f7f7f7;
}

.faq .nav-tabs .nav-link.active {
    color: #ffffff !important;
    background-color: #3c36f2;
    border-color: var(--bs-nav-tabs-link-active-border-color);
}
.faq .nav-tabs
{
    border-bottom: none;
}

.faq-space{
    background: #fff;
    padding: 61px 50px;
    box-shadow: 1px 1px 9px 3px #646a8a14;
    border-radius: 60px 60px 0px 0px;
    margin-top: -63px !important;
}

.accordion-item h2{
font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}
.mt15
{
    margin-top: 15px !important;
}

@media only screen and (max-width: 767px) and (min-width: 300px)
{
    .faq .nav-item {
        width: 100% !important;
         margin-bottom: 10px;
    }
}