.container-fluid{
  padding-right: calc(var(--bs-gutter-x) * 5.5) !important;
  padding-left: calc(var(--bs-gutter-x) * 5.5) !important;
}

@font-face
{
font-family:'Arkitech'; 
  src:url(../src/assets/fonts/Arkitech\ Medium.ttf);
}

h1, h2, h5, h3
{
  font-family:'Arkitech'; 
}
h3 {
  font-size: 1.4rem;
}
h1{
  
}
.card-title
{
  font-family:'Arkitech';
  font-size: 14px !important;
}

.contact-btn{
  background: #fff;
  border: none;
  padding: 9px 50px;
  border-radius: 10px;
  margin-top: 25px;
}

.bnr
{
  background: #636a8a;
  padding: 125px 1px 120px;
  color: #fff;
}

.dot-border {
  border-bottom: 1px dashed #CEE1F8;
  padding: 15px 1px;
  margin-bottom: 35px;
}

.p-card p {
  font-weight: 400;
  font-size: 16px;
  color: #6F8BA4;
  line-height: 28px;
  letter-spacing: 1px;
}

.p-card ul li {
  font-weight: 400;
  font-size: 16px;
  color: #6F8BA4;
  line-height: 28px;
  letter-spacing: 1px;
}
.p-card ul {
  margin-left: 15px;
  margin-bottom: 15px;
}