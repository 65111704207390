 /* .faq
 {
    padding-bottom: 100px;
 }
 
 .faq .nav-link {
    color: #000 !important;
}
.faq .nav-item
{
    width: 50% !important;
}
.faq .nav-item button
{
    width: 100% !important;
    background: #f7f7f7;
}

.faq .nav-tabs .nav-link.active {
    color: #ffffff !important;
    background-color: #3c36f2;
    border-color: var(--bs-nav-tabs-link-active-border-color);
}
.faq .nav-tabs
{
    border-bottom: none;
}

.faq-space{
    background: #fff;
    padding: 61px 50px;
    box-shadow: 1px 1px 9px 3px #646a8a14;
    border-radius: 60px 60px 0px 0px;
    margin-top: -63px !important;
}

.accordion-item h2{
font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
} */

.FontAwesomeIcon
{
    font-size: 20px;
}
.svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 2em;
    overflow: visible;
    vertical-align: -0.125em;
    margin-bottom: 20px;
}