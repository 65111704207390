 .faq
 {
    padding-bottom: 100px;
 }
 
 .faq .nav-link {
    color: #000 !important;
}
.faq .nav-item
{
    width: 50% !important;
}
.faq .nav-item button
{
    width: 100% !important;
    background: #f7f7f7;
}

.faq .nav-tabs .nav-link.active {
    color: #ffffff !important;
    background-color: #3c36f2;
    border-color: var(--bs-nav-tabs-link-active-border-color);
}
.faq .nav-tabs
{
    border-bottom: none;
}

.faq-space{
    background: #fff;
    padding: 61px 50px;
    box-shadow: 1px 1px 9px 3px #646a8a14;
    border-radius: 60px 60px 0px 0px;
    margin-top: -63px !important;
}

.accordion-item h2{
font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}
.abtz
{
    padding: 100px 1px;
}
.how
{
padding: 1px 1px 100px 0px;
}

.p-card2 p{
    border-radius: 8px;
    background: #3533f3;
    padding: 7px 15px;
    color: #fff;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
}