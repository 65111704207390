 .one
 {
    background-image: url(../../assets/images/bg.jpg);
    padding: 120px 0px;
    color:#fff;
 }
 .one p{
   font-size: 1.5rem;
   margin: 15px 1px 35px;
 }
 .one button{
  background: #fff;
  border: none;
  padding: 10px 52px 14px;
  border-radius: 15px;
  margin-right: 10px;
}
.one .svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 2em;
  overflow: visible;
  vertical-align: -0.125em;
  margin-bottom: -8px !important;
  margin-right: 8px;
}
 .one .container-fluid
 {
   padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
   padding-left: calc(var(--bs-gutter-x) * 13.5) !important;
 }
 .two
 {
     
    background: url(../../assets/images/two-bg.jpg) ;
    padding: 200px 1px 180px 1px;
    background-repeat: no-repeat;
  background-attachment: fixed;
 
 }
 .two p
 {
text-align: justify;
font-size: 1.1rem;
margin-top: 35px;
 }
 .three
 {
   background: #555F80;
   padding: 200px 0px;
    text-align: justify;

 }
 .three h2{    font-size: 30px;}
 .three .container-fluid
 {
   padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
   padding-left: calc(var(--bs-gutter-x) * 13.5) !important;
 }

 .four
 {
   padding: 130px 4px;
 }
 .five
 {
   background: #555F80;
   color:#fff;
   padding: 100px 4px;
 }

 
 .bnr-txt{
   display: flex;
   flex-direction: column;
   justify-content: center;
 }

 .three .card{
   padding: 50px 6px;
   border-radius: 25px;
 }

 .ml{
   width: 100% !important;
   border: none !important;
   box-shadow: 1px 0px 35px 0px #d9d9d9d6;
   border-radius: 50px 50px !important;
   padding: 58px 55px;
}


@media only screen and (max-width: 767px) and (min-width: 300px)
{
  .one .container-fluid {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
}
.three .container-fluid {
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
}

.one p {
  font-size: 1rem;
  margin: 15px 1px 35px;
}
.one button {
  background: #000;
  border: none;
  padding: 6px 27px;
  border-radius: 15px;
  margin-right: 10px;
}
.one .svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 2em;
  overflow: visible;
  vertical-align: -0.125em;
  margin-bottom: -8px !important;
  margin-right: 8px !important;
}
.navbar-brand img
{
  width: 10rem;
}
.bnr-txt .h1{
  font-size: 2rem !important;
}
.h1, h1 {
  font-size: 2rem !important;
}
.one h1 {
  margin-top: 20px;
}
.two {
 
  background: url(http://localhost:3000/static/media/two-bg.e51b703….jpg) 0px 0px fixed !important;
  padding: 50px 1px 50px 1px;
}
.three {
  background: #555F80;
  padding: 50px 0px;
  text-align: justify;
}
.three .card {
  padding: 50px 12px;
  border-radius: 25px;
  margin: 0 auto;
}
.four {
  padding: 50px 4px;
}
.ml {
  width: 100% !important;
  border: none !important;
  box-shadow: 1px 0px 35px 0px #d9d9d9d6;
  border-radius: 50px 50px !important;
  padding: 58px 55px;
  margin-bottom: 25px;
}
.five {
  background: #555F80;
  color: #fff;
  padding: 50px 4px;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  background: #fff;
  border-radius: 1px 1px 20px 20px;
}
.navbar-nav a {
  color: #2e2e2e !important;
  text-decoration: none;
  font-size: 18px;
  padding: 6px 6px 8px 22px;
}
.navbar-toggler{
  background: white;
}
}
 

@media only screen and (max-width: 991px) and (min-width: 768px)
{
  .one .container-fluid {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 1.5) !important;
}
.two {
  background: url(../../assets/images/two-bg.jpg) ;
  padding: 60px 1px 60px 1px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: -622px 89px;
}
.three .container-fluid {
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  padding-left: calc(var(--bs-gutter-x) * 2.5) !important;
}
.three .card {
  padding: 50px 12px;
  border-radius: 25px;
  width: 14.5rem !important;
}
.three {
  background: #555F80;
  padding: 70px 0px;
  text-align: justify;
}
.four {
  padding: 70px 4px;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  background: #fff;
  border-radius: 1px 1px 20px 20px;
}
.navbar-nav a {
  color: #2e2e2e !important;
  text-decoration: none;
  font-size: 18px;
  padding: 6px 6px 8px 22px;
}
.navbar-toggler{
  background: white;
}
.h1, h1 {
  font-size: 23px;
}
.one p {
  font-size: 1.1rem;
  margin: 15px 1px 35px;
}
.one button {
  background: #fff;
  border: none;
  padding: 6px 45px;
  border-radius: 15px;
  margin-right: 10px;
}
}

@media only screen and (max-width: 1252px) and (min-width: 992px)
{
  .one .container-fluid {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 5.5) !important;
}
.two {
  background: url(../../assets/images/two-bg.jpg) ;
  padding: 60px 1px 60px 1px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: -492px 89px;
}
.three {
  background: #555F80;
  padding: 70px 0px;
  text-align: justify;
}
.three .container-fluid {
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  padding-left: calc(var(--bs-gutter-x) * 5.5) !important;
}
.three .card {
  padding: 50px 12px;
  border-radius: 25px;
  width: 13.5rem !important;
}
}

@media only screen and (max-width: 1550px) and (min-width: 1256px)
{
  .one .container-fluid {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 5.5) !important;
}
.two {
  background: url(../../assets/images/two-bg.jpg) ;
  padding: 60px 1px 60px 1px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: -392px 89px;
}
.three {
  background: #555F80;
  padding: 70px 0px;
  text-align: justify;
}
.three .container-fluid {
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  padding-left: calc(var(--bs-gutter-x) * 5.5) !important;
}
.three .card {
  padding: 50px 12px;
  border-radius: 25px;
  width: 15rem !important;
}
}

@media only screen and (max-width: 1700px) and (min-width: 1551px)
{
  .three .container-fluid {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 8.5) !important;
  }
  .one .container-fluid {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 8.5) !important;
}
.three .card {
  padding: 50px 12px;
  border-radius: 25px;
  width: 17rem !important;
}
}