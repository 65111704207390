.end
{
    justify-content: end;
}
.sticky-top {
    position: absolute !important;
    top: 0;
    z-index: 1020;
    width: 100%;
}

#basic-navbar-nav{
    width: 100% !important;
    justify-content: end !important;
}
.nav-link
{
    color: #fff !important;
}
.navbar-nav a
{
    color: #fff !important;
    text-decoration: none;
    font-size: 19px;
    padding: 1px 1px 1px 22px;
}
footer{
    padding: 60px 1px;
}
footer ul
{
    list-style-type: none;
    padding-left: 0;
}

@media only screen and (max-width: 767px) and (min-width: 300px)
{
    .navbar-nav a {
        color: #2e2e2e !important;
        text-decoration: none;
        font-size: 18px;
        padding: 6px 6px 8px 22px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px)
{
    .navbar-nav a {
        color: #2e2e2e !important;
        text-decoration: none;
        font-size: 18px;
        padding: 6px 6px 8px 22px;
    }
}